<template>
  <div class="container-sm" style="margin: 20px">
  <main v-if="isMobile === true"  class="form-signin w-100 m-auto">
    <form>
      <img :src="getImgUrl('loginLogo')" width="150" height="120" style="margin: 20px">
      <h1 class="h3 mb-3 fw-normal">تسجيل دخول مدرس</h1>
      <div v-show="validationErrors" class="alert alert-danger" role="alert">
        <ul>
          <li v-for='err in validationErrors'  v-bind:key='err.key' v-bind:value='err.key'>{{ err }}</li>
        </ul>
      </div>
      <div class="mb-3" align="right">
        <label for="userID" class="form-label">كود المدرس:</label>
        <input v-model="record.userID" type="text" class="form-control" id="userIDInput">
      </div>
      <div class="mb-3" align="right">
        <label for="password" class="form-label">كلمة المرور:</label>
        <input v-model="record.password" type="password" class="form-control" id="passwordInput" >
      </div>

      <div class="mb-3">
        <!-- <vue-element-loading :active="isLoading"  spinner="mini-spinner" color="#D0D0D0"/> -->
        <button @click.prevent="performLogin()" class="w-100 btn btn-lg btn-primary" type="submit">تسجيل دخول</button>
      </div>
      <p style="font-size:10px" class="h6">جميع الحقوق محفوظة©</p>
      <p style="font-size:10px" class="h6">علاء نسيم الهليهي</p>
      <p style="font-size:10px" class="h6">app version: {{version}}</p>
      <p style="font-size:10px" class="h6">{{ new Date().getFullYear() }}</p>
    </form>
  </main>
  <h6 v-if="isMobile === false">عفوا لا يمكن الدخول الا من خلال المحمول</h6>

</div>
</template>

<script>
 /* eslint-disable */
import { isMobile } from 'mobile-device-detect'
import packageInfo from '../../package'
export default {
  name: 'LoginView',
  data () {
    return {
      version: packageInfo.version,
      isMobile: isMobile ? true : false,
      validationErrors: '',
      record: {'userID': '', 'password': '', 'account_id': 1},
      isLoading: false
    }
  },
  methods: {
    getImgUrl (pet) {
      var images = require.context('@/assets/', false, /\.png$/)
      return images('./' + pet + '.png')
    },
    performLogin () {
      this.isLoading = true
      localStorage.clear()
      this.validationErrors = ''
      this.$store.dispatch('login', {
        data: this.record
      })
        .then(res => {
          this.$router.push('/home')
          this.isLoading = true
        })
        .catch(err => {
          this.validationErrors = err
          this.isLoading = false
        })
    }
  }
}
</script>
