<template>
  <div class="container" style="padding-top: 10px">
    <vue-element-loading :active="spinnerShow" is-full-screen  spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
    <div class="col" style="padding: 5px">
      <div class="container text-center">
        <div class="row justify-content-evenly">
          <div class="row text-center" style="padding: 0px 0px 0px 0px">
            <div class="col-11" >
              <p class="h4" style="margin:0px; padding:5px;color:#8c1af6"><b>النتائج</b></p>
            </div>
            <div class="col-1" style="padding:2px">
              <svg
                @click="this.$router.push('/home')"
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="currentColor"
                class="bi bi-chevron-left"
                viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  fill="#8c1af6"
                  font-weight="bold"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
              </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="fixedscr" >
      <div class="card" style="width: 100%;">
        <div class="card-body">
          <div v-if='alertStr'  :class="ifSuccess ? 'alert-success' : 'alert-danger'" class="alert" role="alert" style="text-align: right">
            <li v-for='err in alertStr'  v-bind:key='err.key' v-bind:value='err.key'>{{ err }}</li>
          </div>
          <div class="row align-items-end" style="text-align: right;">
            <div class="col-6" style="padding: 0px 5px">
              <label for="exampleFormControlInput1" class="form-label">الفصل</label>
              <select @change="getSubjects()" class="form-select form-select-sm" v-model="classID">
                <option v-for="item in classArr" v-bind:key="item.id" v-bind:value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-6" style="padding: 0px 5px">
              <label for="exampleFormControlInput1" class="form-label">المادة</label>
              <div>
                <vue-element-loading :active="spinnerSubjectShow" spinner="line-scale" color="#E9E9E9" />
                <select @change="getExams()" class="form-select form-select-sm" v-model="Subject">
                  <option v-for="item in SubjectArr" v-bind:key="item.id" v-bind:value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-10" style="padding: 5px 5px">
                <label for="exampleFormControlInput1" class="form-label">الاختبار</label>
                <div>
                  <vue-element-loading :active="spinnerExamShow" spinner="line-scale" color="#E9E9E9" />
                  <select class="form-select form-select-sm" v-model="exam">
                    <option v-for="item in examArr" v-bind:key="item.id" v-bind:value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-2 d-md-block" style="padding: 5px">
                <button @click="GetData()" type="button" class="btn btn-primary btn-sm">عرض</button>
              </div>
            </div>
            <div class="d-grid gap-2" style="padding: 5px">
              <button @click="saveData()" type="button" class="btn btn-success btn-md">حفظ النتيجة</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ol class="list-group ">
        <li class="list-group-item" v-for="(item, index) in records" :key="index">
          <div class="ms-2 me-auto">
            <div >{{item.student_name}}</div>
            <div class="row ">
              <div class="col-4" style="padding:0px">
                <select style= "no-highlight" class="form-select form-select-md" v-model="item.color" :style="`background-color:${item.color}`">
                  <option v-for="select in colors" v-bind:key="select.color" :style="`background-color:${select.color}`" v-bind:value="select.color">
                    {{select.name}}
                    </option>
                </select>
              </div>
              <div class="col-3" style="padding:0px 5px 0px 0px">
                <input type="number" class="form-control" v-model="item.degree" pattern="[0-9]" inputmode="numeric">
              </div>
              <div class="col-3 form-check form-switch" style="padding:0px">
                <input class="form-check-input" type="checkbox" role="switch" v-model="item.status" :value="item.id" :id=" 'item_' + index">
              </div>
              </div>
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>
<style media="screen">
  .fixedscr {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    margin-bottom: 10px
  }
</style>
<script>
 /* eslint-disable */
import VueElementLoading from 'vue-element-loading'
export default {
  components: {
    VueElementLoading
  },
  name: 'ResultView',
  data () {
    return {
      colors: [
        {id: 1, name: '', color: '#FFFFFF', value: ''},
        {id: 2, name: 'أزرق', color: '#0017D8', value: 'CB'},
        {id: 3, name: 'أخضر', color: '#00A910', value: 'CG'},
        {id: 4, name: 'أصفر', color: '#FFF33B', value: 'CY'},
        {id: 5, name: 'أحمر', color: '#D80000', value: 'CR'},
      ],
      bc: '#007700',
      spinnerSubjectShow: false,
      spinnerExamShow: false,
      record: [],
      records: '',
      classArr: '',
      classID: null,
      SubjectArr: [],
      Subject: null,
      examArr: [],
      exam: null,
      spinnerShow: false,
      alertStr: '',
      ifSuccess: false
    }
  },
  created () {
    this.spinnerShow = true
    this.$store.dispatch('callApi', {
      url: 'm-get-classes'
    })
      .then(res => {
        this.spinnerShow = false
        this.classArr = res
      })
      .catch(err => {
        this.spinnerShow = false
        this.Errors = err
      })
    },
    methods: {
      getSubjects () {
        this.Subject = ''
        this.exam = ''
        this.examArr = []
        this.spinnerSubjectShow = true
        this.$store.dispatch('callApi', {
          url: 'm-get-subject',
          data: this.classID
        })
          .then(res => {
            this.spinnerSubjectShow = false
            this.SubjectArr = res
          })
          .catch(err => {
            this.spinnerSubjectShow = false
            this.Errors = err
          })
        },
    // =========================================================================
    getExams () {
      this.examArr = []
      this.exam = ''
      this.spinnerExamShow = true
      this.$store.dispatch('callApi', {
        url: 'm-get-result-header',
        data: this.classID
      })
        .then(res => {
          this.spinnerExamShow = false
          this.examArr = res
        })
        .catch(err => {
          this.spinnerExamShow = false
          this.Errors = err
        })
      },
    // =========================================================================
    GetData () {
      this.spinnerShow = true
      this.alertStr = ''
      this.$store.dispatch('callApi', {
        url: 'm-get-result',
        data: {'class_id': this.classID, 'result_header_id': this.exam, 'subject_id': this.Subject}
      })
        .then(res => {
          this.records = res
          for (let i = 0; i < this.records.length; i++) {
            if (this.records[i].degree !== null) {
              switch(this.records[i].degree.substring(0, 2)) {
                case 'CG':
                  this.records[i].color = '#00A910'
                  this.records[i].degree = this.records[i].degree.replace('CG', '')
                  break
                case 'CY':
                  this.records[i].color = '#FFF33B'
                  this.records[i].degree = this.records[i].degree.replace('CY', '')
                  break
                case 'CR':
                  this.records[i].color = '#D80000'
                  this.records[i].degree = this.records[i].degree.replace('CR', '')
                  break
                case 'CB':
                  this.records[i].color = '#0017D8'
                  this.records[i].degree = this.records[i].degree.replace('CB', '')
                  break
                default:
                  this.records[i].color = null
              }
            }
          }
          this.spinnerShow = false
        })
        .catch(err => {
          this.alertStr = err
          this.ifSuccess = false
          this.spinnerShow = false
        })
    },
    // =========================================================================
    saveData () {
      var degree = 0
      for (let i = 0; i < this.records.length; i++) {
        if (this.records[i].degree === null) {
          degree = ''
        } else {
          degree = this.records[i].degree
        }
        switch(this.records[i].color) {
          case '#00A910':
            this.records[i].degree_str = 'CG' + degree
            break
          case '#FFF33B':
          this.records[i].degree_str = 'CY' + degree
            break
          case '#D80000':
          this.records[i].degree_str = 'CR' + degree
            break
          case '#0017D8':
          this.records[i].degree_str = 'CB' + degree
            break
          default:
          if (degree === '') {
            this.records[i].degree_str = null
          } else {
            this.records[i].degree_str = degree
          }
        }
      }
      this.spinnerShow = true
      this.alertStr = ''
      this.$store.dispatch('callApi', {
        url: 'm-save-result',
        data: {'rec': this.records, 'result_header_id': this.exam, 'subject_id': this.Subject}
      })
        .then(res => {
          this.spinnerShow = false
          this.ifSuccess = true
          this.alertStr = {0: 'تم الحفظ بنجاح'}
        })
        .catch(err => {
          this.alertStr = err
          this.spinnerShow = false
          this.ifSuccess = false
        })
    }
  }
}
</script>
<style>
.green{
    background-color: #00A910;
}
.white{
    background-color: #FFFFFF;
}

.yellow{
    background-color: #FFF33B;
}

.red{
    background-color: #D80000;
}

.blue{
    background-color: #0017D8;
}

 .no-highlight{
    user-select: none;
    -moz-user-select: none;
    -webkit-text-select: none;
    -webkit-user-select: none;
 }

</style>
