<template>
  <div class="container" style="padding-top: 10px">
    <vue-element-loading :active="spinnerShow" is-full-screen  spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
    <div class="col" style="padding: 5px">
      <div class="container text-center">
        <div class="row justify-content-evenly">
          <div class="row text-center" style="padding: 0px 0px 0px 0px">
            <div class="col-11" >
              <p class="h4" style="margin:0px; padding:5px;color:#8c1af6"><b>الغياب</b></p>
            </div>
            <div class="col-1" style="padding:2px">
              <svg
              @click="this.$router.push('/home')"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16">
              <path
              fill-rule="evenodd"
              fill="#8c1af6"
              font-weight="bold"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fixedscr" >
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <h5 class="card-title">{{dayName}}</h5>
        <div class="row align-items-end" style="text-align: right;">
          <div class="col-12" style="padding: 0px 5px">
            <label for="exampleFormControlInput1" class="form-label">الفصل</label>
            <select @change="setclassMaxLectures()" class="form-select form-select-sm" v-model="classID">
              <option v-for="item in classArr" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="row text-center" style="padding: 10px 0px 0px 0px">
            <div class="col">
              العدد: {{records.length}}
            </div>
            <div class="col">
              غائب: {{stOut.length}}
            </div>
            <div class="col">
              حاضر: {{stIn.length}}
            </div>
          </div>
          <div v-if="isActive && records.length > 0" class="d-grid gap-2" style="padding: 10px 10px 0px 10px">
            <button @click="saveData()" type="button" class="btn btn-primary btn-md">حفظ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ul class="list-group" style="text-align: right;padding-bottom: 15px">
    <li :class="item.status ? 'list-group-item-primary' : ''" class="list-group-item" v-for="(item, index) in records" :key="item.id" @click="Setabsence(item)" >{{index+1}}- {{item.student_name}}</li>
  </ul>
</div>
</template>
<style media="screen">
  .fixedscr {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    margin-bottom: 10px
  }
</style>
<script>
 /* eslint-disable */
import VueElementLoading from 'vue-element-loading'
export default {
  components: {
    VueElementLoading
  },
  name: 'AbsencesView',
  data () {
    return {
      record: [],
      records: '',
      periodsRec: [],
      classArr: '',
      classID: null,
      dayName: '',
      dayDate: '',
      spinnerShow: false,
      isActive: false,
      classMaxLectures: 0,
      absenceHeaderID: null
    }
  },
  created () {
    this.spinnerShow = true
    this.$store.dispatch('callApi', {
      url: 'm-get-class'
    })
      .then(res => {
        this.spinnerShow = false
        this.classArr = res.data
        this.periodsRec = res.periods
        this.dayName = res.date[0].day_name
        this.dayDate = res.date[0].day
      })
      .catch(err => {
        this.spinnerShow = false
        this.Errors = err
      })
    },
    computed: {
      periods() {
        return this.periodsRec.filter(period => period.id <= this.classMaxLectures)
      },
      stIn() {
        if (this.records.length > 0) {
          return this.records.filter(record => !record.status)
        } else {
          return []
        }
      },
      stOut() {
        if (this.records.length > 0) {
          return this.records.filter(record => record.status)
        } else {
          return []
        }
      }
    },
    methods: {
    setclassMaxLectures () {
      this.GetData()
      let i = this.classArr.map(record => record.id).indexOf(this.classID)
      this.classMaxLectures = this.classArr[i].max_lectures
    },
    // =========================================================================
    getImgUrl (pet) {
      var images = require.context('@/assets/', false, /\.png$/)
      return images('./' + pet + '.png')
    },
    // =========================================================================
    GetData () {
      this.spinnerShow = true
      this.$store.dispatch('callApi', {
        url: 'm-get-absence',
        data: {'class_id': this.classID, 'absence_date': this.dayDate}
      })
        .then(res => {
          this.absenceHeaderID = res.absence_header_id
          this.isActive = res.active
          this.records = res.data
          this.spinnerShow = false
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
        })
    },
    // =========================================================================
    saveData () {
      this.spinnerShow = true
      this.$store.dispatch('callApi', {
        url: 'm-save-absence',
        data: {'rec': this.records, 'class_id': this.classID, 'absence_date': this.dayDate, 'absence_header_id': this.absenceHeaderID}
      })
        .then(res => {
          this.spinnerShow = false
          alert('تم الحفظ بنجاح')
          this.isActive = false
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
        })
    },
    // =========================================================================
    Setabsence (rec) {
      let i = this.records.map(record => record.id).indexOf(rec.id)
      this.records[i].status = !this.records[i].status
    }
  }
}
</script>
