<template>
  <div class="wrap row">
    <div class="item">
      <router-link to="/personaldata">
        <a href="#">
          <img :src="getImgUrl('01')" style="width:100%;">
        </a>
      </router-link>
    </div>
    <div class="item">
      <router-link to="/absences">
        <a href="#">
        <img :src="getImgUrl('02')" style="width:100%;">
      </a>
    </router-link>
    </div>
    <div class="item">
      <router-link to="/homework">
        <a href="#">
        <img :src="getImgUrl('03')" style="width:100%;">
      </a>
    </router-link>
    </div>
    <div class="item">
      <router-link to="/timetable">
        <a href="#">
        <img :src="getImgUrl('05')" style="width:100%;">
      </a>
    </router-link>
    </div>
    <div class="item">
      <router-link to="/notification">
        <a href="#">
        <!-- <span class="notify-badge">55</span> -->
        <img :src="getImgUrl('06')" style="width:100%;">
      </a>
    </router-link>
    </div>
    <div class="item">
      <router-link to="/exams">
        <a href="#">
        <img :src="getImgUrl('04')" style="width:100%;">
      </a>
    </router-link>
    </div>
    <div class="item">
      <router-link to="/result">
        <a href="#">
        <img :src="getImgUrl('07')" style="width:100%;">
      </a>
    </router-link>
    </div>
    <div class="item">
      <a href="#" @click.prevent="logOut()">
        <img :src="getImgUrl('09')" style="width:100%;">
      </a>
    </div>
  </div>
</template>

<style media="screen">
.wrap {
  width:90%;
  margin:0 auto;
}
.wrap div {
  width:46%;
  padding:5px;
  margin:2%;
  float:left;
  background:#F0F0F0;
  border-radius: 25px;
  border: 2px solid #E6E6E6
}
.item {
    position:relative;
    padding-top:20px;
    display:inline-block;
}
.notify-badge{
    position: absolute;
    right:-8px;
    top:-8px;
    background:red;
    text-align: center;
    border-radius: 40px 40px 40px 40px;
    color:white;
    padding:0px 10px;
    font-size:20px;
}
</style>

<script>
export default {
  name: 'MenuPage',
  methods: {
    getImgUrl (pet) {
      var images = require.context('@/assets/', false, /\.png$/)
      return images('./' + pet + '.png')
    },
    logOut () {
      sessionStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>
