import { createStore } from 'vuex'
import api from './Api'
export default createStore({
  state: {
    currentPage: ''
  },
  getters: {
  },
  mutations: {
    changeCurrentPage(state, str) {
      state.currentPage = str
    }
  },
  actions: {
  },
  modules: {
    api
  }
})
