 <template>
  <div class="container" style="padding-top: 10px">
    <vue-element-loading :active="spinnerShow" is-full-screen  spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
    <div class="col" style="padding: 5px">
      <div class="container text-center">
        <div class="row justify-content-evenly">
          <div class="row text-center" style="padding: 0px 0px 0px 0px">
            <div class="col-11" >
              <p class="h4" style="margin:0px; padding:5px;color:#8c1af6"><b>الإشعارات</b></p>
            </div>
            <div class="col-1" style="padding:2px">
              <svg
                @click="this.$router.push('/home')"
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="currentColor"
                class="bi bi-chevron-left"
                viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  fill="#8c1af6"
                  font-weight="bold"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
              </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div>
      <div class="card" style="width: 100%;">
        <div class="card-body">
          <div class="row align-items-end" style="text-align: right;">
            <div class="col-12" style="padding: 0px 5px">
              <label for="exampleFormControlInput1" class="form-label">الإشعارات:</label>
              <vue-element-loading :active="spinnerNotificationHeader" spinner="line-scale" color="#E9E9E9" />
              <select class="form-select form-select-sm" v-model="record">
                <option v-for="item in notificationArr" v-bind:key="item.id" v-bind:value="item">
                  {{ item.date }} {{ item.notice_name }}
                </option>
              </select>
            </div>
            <div class="d-grid gap-2" style="padding: 15px 5px">
              <button @click="newNotification" type="button" class="btn btn-info btn-md" data-bs-toggle="modal" data-bs-target="#newModal">جديد</button>
            </div>
            <div v-show = "record" class="">
              <div class="mb-3"  style="text-align: right">
                <label for="exampleFormControlInput1" class="form-label">العنوان:</label>
                <input type="text" class="form-control" v-model="record.notice_name">
              </div>
              <div class="mb-3"  style="text-align: right">
                <label for="exampleFormControlTextarea1" class="form-label">نص الاشعار:</label>
                <textarea class="form-control" rows="3"  v-model="record.notice"></textarea>
              </div>
              <div class="form-check form-switch" style="text-align: right;margin: 5px 0px; padding: 0px 0px 0px 50px">
                <input class="form-check-input" type="checkbox" role="switch" :id=" 'brands_' + record.id" v-model="record.status" :value="record.id">
                <label class="form-check-label" for="flexSwitchCheckChecked">الحالة:</label>
              </div>
            </div>
            <div v-if="record" class="d-grid gap-2" style="padding: 15px 5px">
              <button @click="updateData()" type="button" class="btn btn-success btn-md">حفظ الاشعار</button>
            </div>
            <label v-if="record" for="exampleFormControlInput1" class="form-label">الفصل:</label>
            <div v-if="record" class="input-group col-12" style="padding: 0px 5px">
              <span v-if="records" @click="getClassStudents()" class="input-group-text" id="basic-addon1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ubuntu" viewBox="0 0 16 16">
                  <path d="M2.273 9.53a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.547Zm9.467-4.984a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546ZM7.4 13.108a5.535 5.535 0 0 1-3.775-2.88 3.273 3.273 0 0 1-1.944.24 7.4 7.4 0 0 0 5.328 4.465c.53.113 1.072.169 1.614.166a3.253 3.253 0 0 1-.666-1.9 5.639 5.639 0 0 1-.557-.091Zm3.828 2.285a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546Zm3.163-3.108a7.436 7.436 0 0 0 .373-8.726 3.276 3.276 0 0 1-1.278 1.498 5.573 5.573 0 0 1-.183 5.535 3.26 3.26 0 0 1 1.088 1.693ZM2.098 3.998a3.28 3.28 0 0 1 1.897.486 5.544 5.544 0 0 1 4.464-2.388c.037-.67.277-1.313.69-1.843a7.472 7.472 0 0 0-7.051 3.745Z"/>
                </svg>
              </span>
              <select @change="getClassStudents()" class="form-select form-select-sm" v-model="classID">
                <option v-for="item in classArr" v-bind:key="item.id" v-bind:value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="records" class="card" style="width: 100%;padding-top: 10px; margin-top: 10px">
      <div class="card-body" style="padding: 2px 4px; color:blue">
        <div class="form-check form-switch">
          <input @change="selectAll()" v-model="checkAll" class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
          <label class="form-check-label" for="flexSwitchCheckChecked">جميع الطلاب</label>
        </div>
      </div>
    </div>
    <div>
      <ol class="list-group ">
        <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(item, index) in records" :key="index">
            <div class="row text-center" style="padding: 10px 0px 0px 0px">
              {{item.student_name}}
              <small v-if="item.first_seen && item.student_notification_id" style="text-align: right; color:green">
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-alarm"
                    viewBox="0 0 16 16"
                  >
                  <path
                  fill="green"
                  font-weight="bold"
                  d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/>
                  <path
                  fill="green"
                  font-weight="bold"
                  d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/>
                </svg> : {{item.first_seen}}
              </small>
              <small v-if="!item.first_seen && item.student_notification_id" style="text-align: right; color:red">
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-alarm"
                    viewBox="0 0 16 16"
                  >
                  <path
                  fill="red"
                  font-weight="bold"
                  d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/>
                  <path
                  fill="red"
                  font-weight="bold"
                  d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/>
                </svg> : لم تتم المشاهدة
              </small>
            </div>
            <div class="row text-center" style="padding: 10px 0px 0px 0px">
              <div class="col-3 form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" v-model="item.status" :value="item.id" :id=" 'item_' + index">
              </div>
            </div>
        </li>
      </ol>
    </div>
    <div v-if="records" class="d-grid gap-2" style="padding: 15px 5px">
      <button @click="saveStudentsData()" type="button" class="btn btn-success btn-md">حفظ الطلاب</button>
    </div>
  </div>
  <!-- Modal -->
<div class="modal fade" id="newModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mb-3"  style="text-align: right">
          <label for="exampleFormControlInput1" class="form-label">العنوان</label>
          <input type="text" class="form-control" v-model="notice_name">
        </div>
        <div class="mb-3"  style="text-align: right">
          <label for="exampleFormControlTextarea1" class="form-label">نص الاشعار</label>
          <textarea class="form-control" rows="3"  v-model="notice"></textarea>
        </div>
        <div class="form-check form-switch" style="text-align: right;margin: 5px 0px; padding: 0px 0px 0px 50px">
          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="status" >
          <label class="form-check-label" for="flexSwitchCheckChecked">الحالة</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">خروج</button>
        <button type="button" @click="saveNotification()" class="btn btn-primary">حفظ</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
 /* eslint-disable */
import VueElementLoading from 'vue-element-loading'
export default {
  components: {
    VueElementLoading
  },
  name: 'NotificationView',
  data () {
    return {
      spinnerNotificationHeader: false,
      record: '',
      records: '',
      notificationArr: [],
      classID: null,
      classArr: '',
      spinnerShow: false,
      notice_name: '',
      notice: '',
      status: true,
      checkAll: false
    }
  },
  created () {
    this.spinnerNotificationHeader = true
    this.$store.dispatch('callApi', {
      url: 'm-get-notification-header'
    })
      .then(res => {
        this.spinnerNotificationHeader = false
        this.notificationArr = res.data
        this.classArr = res.classes
      })
      .catch(err => {
        this.spinnerNotificationHeader = false
        alert(err[0])
      })
    },
    methods: {
  // =========================================================================
    updateData () {
      this.spinnerShow = true
      this.$store.dispatch('callApi', {
        url: 'm-save-notification',
        data: this.record
      })
        .then(res => {
          this.spinnerShow = false
          this.notificationArr = res
          alert('تم الحفظ بنجاح')
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
        })
    },
    // =========================================================================
      saveNotification () {
        this.spinnerShow = true
        this.$store.dispatch('callApi', {
          url: 'm-save-notification',
          data: {id: 0, 'notice_name': this.notice_name, 'notice': this.notice, 'status': this.status}
        })
          .then(res => {
            this.spinnerShow = false
            this.notificationArr = res
            alert('تم الحفظ بنجاح')
          })
          .catch(err => {
            alert(err[0])
            this.spinnerShow = false
          })
      },
    // =========================================================================
    newNotification () {
      this.record = ''
      this.records = ''
    },
    // =========================================================================
    getClassStudents () {
      this.spinnerShow = true
      this.$store.dispatch('callApi', {
        url: 'm-get-notification-class',
        data: {class_id: this.classID, notify_id: this.record.id}
      })
        .then(res => {
          this.spinnerShow = false
          this.records = res
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
        })
    },
    // =========================================================================
    selectAll () {
      for (let i = 0; i < this.records.length; i++) {
        this.records[i].status = this.checkAll
      }
    },
    // =========================================================================
    saveStudentsData () {
      this.spinnerShow = true
      this.$store.dispatch('callApi', {
        url: 'm-save-notification-class',
        data: {notify_id: this.record.id, rec: this.records}
      })
        .then(res => {
          this.spinnerShow = false
          alert('تم الحفظ بنجاح')
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
        })
    }
  }
}
</script>
