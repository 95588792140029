
 /* eslint-disable */
import axios from 'axios'
import router from '../router'
const apiUrl = 'https://sa.amals.pro/api/api/'
const apiUploadUrl = 'https://sa.amals.pro/api/api/'
export default {
  actions: {
    // ==============================================================
    callApi ({commit}, { url, data }) {
      return new Promise((resolve, reject) => {
        const token = sessionStorage.getItem('token')
        axios.post(apiUrl + url, {
          'token': token,
          data
        })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            // --------------------Unauthorized------------------
            if (err.response.status === 500) {
              router.push({path: 'Err500'})
            }
            // --------------------page not found------------------
            if (err.response.status === 404) {
              router.push({path: 'Err404'})
            }
            // -------------------user not login Unauthorized-------------------
            if (err.response.status === 401) {
              router.push({path: 'Err401'})
            }
            reject(err.response.data.errors)
          })
      })
    },
    // ==============================================================
    uploadFiles ({commit}, {file, type, id}) {
      return new Promise((resolve, reject) => {
        const token = sessionStorage.getItem('token')
        const data = new FormData()
        data.append('photo',  file)
        data.append('token',  token)
        data.append('id',  id)
        data.append('type',  type)
        axios.post(apiUploadUrl + "m-upload-files", data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            // --------------------Unauthorized------------------
            if (err.response.status === 500) {
              router.push({path: 'Err500'})
            }
            // --------------------page not found------------------
            if (err.response.status === 404) {
              router.push({path: 'Err404'})
            }
            // -------------------user not login Unauthorized-------------------
            if (err.response.status === 401) {
              router.push({path: 'Err401'})
            }
            reject(err.response.data.errors)
          })
      })
    },
    // ==============================================================
    login ({commit}, {data}) {
      return new Promise((resolve, reject) => {
        axios.post(apiUrl + 'auth/t-login', {
          account_id: data.account_id,
          username: data.userID,
          password: data.password
        })
          .then(res => {
            sessionStorage.setItem('isLogin', 'True')
            sessionStorage.setItem('token', res.data.access_token)
            sessionStorage.setItem('avt_img', res.data.av_img)
            sessionStorage.setItem('name', res.data.name)
            sessionStorage.setItem('uid', res.data.uid)
            sessionStorage.setItem('classes', res.data.Classes)
            resolve(res.data)
          })
          .catch(err => {
            reject(err.response.data.errors)
          })
      })
    },
    // ==============================================================
    uplioad ({commit}, { url, data }) {
      return new Promise((resolve, reject) => {
        const token = sessionStorage.getItem('token')
        axios.post(apiUrl + url, {
          'token': token,
          data
        })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            // --------------------Unauthorized------------------
            if (err.response.status === 500) {
              router.push({path: 'Err500'})
            }
            // --------------------page not found------------------
            if (err.response.status === 404) {
              router.push({path: 'Err404'})
            }
            // -------------------user not login Unauthorized-------------------
            if (err.response.status === 401) {
              router.push({path: 'Err401'})
            }
            reject(err.response.data.errors)
          })
      })
    }
    // ==============================================================
  }
}
