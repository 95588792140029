<template>
  <div class="container" style="padding-top: 10px">
    <vue-element-loading :active="spinnerShow" is-full-screen  spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
    <div class="col" style="padding: 5px">
      <div class="container text-center">
        <div class="row justify-content-evenly">
          <div class="row text-center" style="padding: 0px 0px 0px 0px">
            <div class="col-11" >
              <p class="h4" style="margin:0px; padding:5px;color:#8c1af6"><b>الاسئلة</b></p>
            </div>
            <div class="col-1" style="padding:2px">
              <svg
              @click="this.$router.push('/home')"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16">
              <path
              fill-rule="evenodd"
              fill="#8c1af6"
              font-weight="bold"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="row align-items-end" style="text-align: right;">
          <div class="col-12" style="padding: 5px">
            <label for="exampleFormControlInput1" class="form-label">الصف:</label>
            <select @change="getUnits(1)" class="form-select form-select-sm" v-model="grade">
              <option v-for="item in grades" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.grade_name }}
              </option>
            </select>
          </div>
          <div class="col-12" style="padding: 5px">
            <label for="exampleFormControlInput1" class="form-label">المادة:</label>
            <select @change="getUnits(2)" class="form-select form-select-sm" v-model="subject">
              <option v-for="item in subjects" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.subject_name }}
              </option>
            </select>
          </div>
          <div class="col-12" style="padding: 5px">
            <label for="exampleFormControlInput1" class="form-label">القسم:</label>
            <select @change="getUnits(3)" class="form-select form-select-sm" v-model="section">
              <option v-for="item in sections" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.date }} {{ item.section_name }}
              </option>
            </select>
          </div>
          <div class="col-12" style="padding: 5px">
            <label for="exampleFormControlInput1" class="form-label">التيرم:</label>
            <select @change="getUnits(4)" class="form-select form-select-sm" v-model="term">
              <option v-for="item in terms" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.term_name }}
              </option>
            </select>
          </div>
          <div class="col-10" style="padding: 0px 5px">
            <label for="exampleFormControlInput1" class="form-label">الوحدة:</label>
            <vue-element-loading :active="spinnerUnit" spinner="line-scale" color="#E9E9E9" />
            <select @change="getUnits(5)" class="form-select form-select-sm" v-model="unit">
              <option v-for="item in units" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.date }} {{ item.unit_name }}
              </option>
            </select>
          </div>
          <div class="d-grid col-2" style="padding: 10px 2px 1px 5px">
            <button :disabled="newDisable" sable type="button" class="btn btn-primary btn-sm"  data-bs-toggle="modal" data-bs-target="#unitModal" >جديد</button>
          </div>
          <div class="col-10" style="padding: 0px 5px">
            <label for="exampleFormControlInput1" class="form-label">الدرس:</label>
            <vue-element-loading :active="spinnerUnit" spinner="line-scale" color="#E9E9E9" />
            <select class="form-select form-select-sm" v-model="lesson">
              <option v-for="item in lessons" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.date }} {{ item.lesson_name }}
              </option>
            </select>
          </div>
          <div class="d-grid col-2" style="padding: 10px 2px 1px 5px">
            <button :disabled="newLessonDisable" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#lessonModal">جديد</button>
          </div>
          <div class="col-12" style="padding: 5px">
            <label for="exampleFormControlInput1" class="form-label">نوع السؤال:</label>
            <select @change = "setDownloadFile()" class="form-select form-select-sm" v-model="question">
              <option v-for="item in questions" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="d-grid col-4" style="padding: 10px 2px 1px 5px">
            <a :class="question === 0 ? 'disabled' : ''" class=" btn btn-outline-primary btn-sm"  :download="downloadFile.file" :href="downloadFile.url" role="button">قالب
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-spreadsheet" viewBox="0 0 16 16">
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z"/>
              </svg>
            </a>
          </div>
          <div class="d-grid col-4" style="padding: 10px 2px 1px 5px">
            <button :disabled="lesson === 0 || question === 0" @click="$refs.file.click()" type="button" class="btn btn-outline-primary btn-sm">تصدير
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z"/>
              </svg>
            </button>
          </div>
          <div class="d-grid col-4" style="padding: 10px 2px 1px 5px">
            <button :disabled="lesson === 0 || question === 0" @click="importQuestions()" type="button" class="btn btn-outline-primary btn-sm"  data-bs-toggle="modal" data-bs-target="#importModal">استيراد
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
              </svg>
            </button>
          </div>
          <div class="upload-btn-wrapper">
            <input
            ref="file"
            style="display: none"
            type="file"
            v-on:change="addfile($event)"
            placeholder="Upload file"
            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Unit Modal start -->
<div class="modal fade" id="unitModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body">
        <div class="mb-3"  style="text-align: right">
          <label for="exampleFormControlInput1" class="form-label">الوحدة:</label>
          <p style="color: red; font-size:12px">:يجب كتابه رقم الوحدة ثم الوحدة مثل  <br> <small>الوحدة الأولى: ×××××××</small> </p>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="unitName"></textarea>
        </div>
        <div class="mb-3"  style="text-align: right">
          <label class="form-label">الترتيب:</label>
          <select class="form-select form-select-sm" v-model="ranking">
            <option v-for="num in 10" v-bind:key="num" v-bind:value="num">
              {{num}}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">خروج</button>
        <button type="button" @click="saveUnit()" class="btn btn-primary">حفظ</button>
      </div>
    </div>
  </div>
</div>
<!-- Unit Modal end -->
<!-- Lesson Modal start -->
<div class="modal fade" id="lessonModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body">
        <div class="mb-3"  style="text-align: right">
          <label for="exampleFormControlInput1" class="form-label">الدرس:</label>
          <p style="color: red; font-size:12px">:يجب كتابه رقم الدرس ثم الوحدة مثل  <br> <small>الدرس الأول: ×××××××</small> </p>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="lesoneName"></textarea>
        </div>
        <div class="mb-3"  style="text-align: right">
          <label class="form-label">الترتيب:</label>
          <select class="form-select form-select-sm" v-model="ranking">
            <option v-for="num in 10" v-bind:key="num" v-bind:value="num">
              {{num}}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">خروج</button>
        <button type="button" @click="saveLesson()" class="btn btn-primary">حفظ</button>
      </div>
    </div>
  </div>
</div>
<!-- Lesson Modal end -->
<!-- Import Modal start -->
<div class="modal fade" id="importModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body">
        <vue-element-loading :active="spinnerImport" spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
        <div>
          <div v-if="importRec" class="mb-3"  style="text-align: right">
            <label for="exampleFormControlInput1" class="form-label">عدد الأسئلة المستوردة {{importRec.length}} سؤال</label>
          </div>
          <json-excel v-if="importRec" :data="importRec" name="ama.xls">
            <button type="button" class="btn btn-success btn-sm">حفظ
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
              </svg>
            </button>
          </json-excel>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">خروج</button>
      </div>
    </div>
  </div>
</div>
<!-- Import Modal end -->
</template>
<script>
/* eslint-disable */
import * as XLSX from 'xlsx'
import VueElementLoading from 'vue-element-loading'
import JsonExcel from "vue-json-excel3"
export default {
 components: {
  JsonExcel,
   VueElementLoading
 },
 name: 'QuestionsView',
 computed: {
   sections () {
     let i = this.subjects.map(record => record.id).indexOf(this.subject)
     if (i > -1) {
       return this.subjects[i].sections
     } else {
       return []
     }
   },
   lessons () {
     let i = this.units.map(record => record.id).indexOf(this.unit)
     if (i > -1) {
       return this.units[i].lessons
     } else {
       return []
     }
   }
   },
 data () {
   return {
     grades: [],
     subjects: [],
     terms: [],
     units: [],
     downloadFile: {file: '', url: ''},
     questions: [{id:1, name: 'صح و غلط'}, {id:2, name: 'إختيارى'}],
     grade: 0,
     subject: 0,
     term: 0,
     unit: 0,
     section: 0,
     lesson: 0,
     question: 0,
     lesoneName: '',
     newDisable: true,
     newLessonDisable: true,
     spinnerUnit: false,
     record: '',
     records: '',
     notificationArr: [],
     classID: null,
     classArr: '',
     spinnerShow: false,
     spinnerImport: false,
     unitName: '',
     notice: '',
     status: true,
     checkAll: false,
     ranking: null,
     file: File,
     arrayBuffer: null,
     tableHeader: [],
     importRec: null
   }
 },
 created () {
   this.spinnerShow = true
   this.$store.dispatch('callApi', {
     url: 'm-get-exam-question-filter'
   })
     .then(res => {
       this.spinnerShow = false
       this.grades = res.grades
       this.subjects = res.subject
       this.terms = res.terms
     })
   },
   methods: {
   // =========================================================================
   getUnits (id) {
     if (id === 1) {
       this.subject = 0
       this.section = 0
       this.term = 0
       this.unit = 0
       this.lesson = 0
     }
     if (id === 2) {
       this.section = 0
       this.term = 0
       this.unit = 0
       this.lesson = 0
     }
     if (id === 3) {
       this.term = 0
       this.unit = 0
       this.lesson = 0
     }
     if (id === 4) {
       this.unit = 0
       this.lesson = 0
     }
     if (id === 5) {
       this.lesson = 0
     }
     //---------------------------------
     if (this.grade > 0 && this.subject > 0 && this.term > 0) {
       if (this.unit > 0) {
         this.newLessonDisable = false
       } else {
         this.newLessonDisable = true
       }
       //---------------------------------
       if (id !== 5) {
         this.newDisable = false
         this.spinnerUnit = true
         this.$store.dispatch('callApi', {
           url: 'm-get-exam-lesson',
           data: {grade: this.grade, subject: this.subject, section: this.section, term: this.term}
         })
         .then(res => {
           this.spinnerUnit = false
           this.units = res
         })
       }
     } else {
       this.newDisable = true
       this.newLessonDisable = true
     }
   },
   // =========================================================================
   importQuestions () {
     this.importRec = null
     this.spinnerImport = true
     this.$store.dispatch('callApi', {
       url: 'm-import-exam-questions',
       data: {
         question: this.question,
         lesson: this.lesson
       }
     })
       .then(res => {
         this.spinnerImport = false
         this.importRec = res
       })
   },
   // =========================================================================
   saveUnit () {
     this.spinnerUnit = true
     this.$store.dispatch('callApi', {
       url: 'm-save-exam-unit',
       data: {
         id: 0,
         grade_id: this.grade,
         subject_id: this.subject,
         section_id: this.section,
         term_id: this.term,
         unit_name: this.unitName,
         ranking: this.ranking
       }
     })
       .then(res => {
         this.spinnerUnit = false
         this.units.push(res)
         alert('تم الحفظ بنجاح')
         this.lesoneName = ''
         this.unitName = ''
       })
       .catch(err => {
         this.spinnerUnit = false
         alert(err[0])
       })
   },
   // =========================================================================
   setDownloadFile () {
     if (this.question === 1) {
       this.downloadFile.file = 'trueFalse.xlsx'
       this.downloadFile.url = 'https://api.ama-lang-sch.com/system/storage/app/exam-temp-files/trueFalse.xlsx'
     } else if (this.question === 2) {
       this.downloadFile.file = 'choose.xlsx'
       this.downloadFile.url = 'https://api.ama-lang-sch.com/system/storage/app/exam-temp-files/choose.xlsx'
     }
   },
   // =========================================================================
   addfile (event) {
     this.file = event.target.files[0]
     let fileReader = new FileReader()
     fileReader.readAsArrayBuffer(this.file)
     fileReader.onload = () => {
       this.arrayBuffer = fileReader.result
       var data = new Uint8Array(this.arrayBuffer)
       var arr = []
       for (var i = 0; i !== data.length; ++i) {
         arr[i] = String.fromCharCode(data[i])
       }
       var bstr = arr.join('')
       var workbook = XLSX.read(bstr, { type: 'binary' })
       var firstSheetName = workbook.SheetNames[0]
       var worksheet = workbook.Sheets[firstSheetName]
       var sheetRecords = XLSX.utils.sheet_to_json(worksheet, { raw: true })
       if (sheetRecords.length > 0) {
         if (confirm('عدد الأسئلة فى الملف ' + sheetRecords.length + ' سؤال هل تريد رفعها للتطبيق؟') == true) {
           this.spinnerUnit = true
           this.$store.dispatch('callApi', {
             url: 'm-upload-exam-question',
             data: {
               lesson: this.lesson,
               question: this.question,
               rec: sheetRecords
             }
           })
             .then(res => {
               this.spinnerUnit = false
               alert('تم حفظ  ' + res + ' سؤال')
               this.file = ''
             })
             .catch(err => {
               this.spinnerUnit = false
               this.file = ''
               alert(err[0])
             })
         }
       }
     }
   },
   // =========================================================================
   saveLesson () {
     this.spinnerUnit = true
     this.$store.dispatch('callApi', {
       url: 'm-save-exam-lesson',
       data: {
         id: 0,
         exam_unit_id: this.unit,
         lesson_name: this.lesoneName,
         ranking: this.ranking
       }
     })
       .then(res => {
         this.spinnerUnit = false
         alert('تم الحفظ بنجاح')
       })
       .catch(err => {
         alert(err[0])
         this.spinnerShow = false
       })
   }
 }
}
</script>
