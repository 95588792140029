import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue'
import MenuPage from '../components/MenuPage.vue'
import Login from '../views/LoginView.vue'
import PersonalData from '../views/PersonalData'
import AbsencesView from '../views/AbsencesView'
import TimeTable from '../views/TimeTable'
import HomeWork from '../views/HomeWork'
import ResultView from '../views/ResultView'
import ExamsView from '../views/exam/ExamsView'
import NotificationView from '../views/NotificationView'
import Questions from '../views/Questions'

const routes = [
  // ---------- Login Page -----------
  {
  path: '/login',
  name: 'Login',
  component: Login
  },
// ---------- Login Page -----------
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    beforeEnter: (to, from, next) => {
      if (sessionStorage.getItem('isLogin')) {
        next()
      } else {
        next('/login')
      }
    },
    children: [
      {
        path: '/home',
        name: 'MenuPage',
        component: MenuPage
      },
      {
        path: '/personaldata',
        name: 'PersonalData',
        component: PersonalData
      },
      {
        path: '/absences',
        name: 'AbsencesView',
        component: AbsencesView
      },
      {
        path: '/homework',
        name: 'HomeWork',
        component: HomeWork
      },
      {
        path: '/result',
        name: 'ResultView',
        component: ResultView
      },
      {
        path: '/exams',
        name: 'ExamsView',
        component: ExamsView
      },
      {
        path: '/notification',
        name: 'NotificationView',
        component: NotificationView
      },
      {
        path: '/timetable',
        name: 'TimeTable',
        component: TimeTable
      },
      {
        path: '/questions',
        name: 'Questions',
        component: Questions
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
