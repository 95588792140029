<template>
  <div class="container" style="padding-top: 10px">
    <vue-element-loading :active="spinnerShow" is-full-screen  spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
    <div class="col" style="padding: 5px">
      <div class="container text-center">
        <div class="row justify-content-evenly">
          <div class="row text-center" style="padding: 0px 0px 0px 0px">
            <div class="col-11" >
              <p class="h4" style="margin:0px; padding:5px;color:#8c1af6"><b>الاختبارات</b></p>
            </div>
            <div class="col-1" style="padding:2px">
              <svg
              @click="this.$router.push('/home')"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16">
              <path
              fill-rule="evenodd"
              fill="#8c1af6"
              font-weight="bold"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="row align-items-end" style="text-align: right;">
          <div class="col-12" style="padding: 5px 5px 0px 5px">
            <div class="col-12">
              <label for="exampleFormControlInput1" class="form-label">الاختبار:  <small v-if="record.id > 0" style="color:red">[{{record.exam_name}}]</small></label>
              <vue-element-loading :active="spinnerExam" spinner="line-scale" color="#E9E9E9" />
              <select class="form-select form-select-sm" v-model="record">
                <option v-for="item in examArr" v-bind:key="item.id" v-bind:value="item" style = "font-size:11px">
                  <b>الصف:{{ item.grade }} {{ item.exam_name }}</b>
                </option>
              </select>
            </div>
          </div>
          <br>
          <div :class="record.id > 0 ? 'col-4' : 'col-12'" style="padding: 0px">
            <div class="d-grid gap-2" style="padding: 5px">
              <button @click="newExam()" data-bs-toggle="modal" data-bs-target="#newExamModal" type="button" class="btn btn-outline-primary btn-sm">جديد</button>
            </div>
          </div>
          <div v-if="record.id > 0" class="col-4" style="padding: 0px">
            <div class="d-grid gap-2" style="padding:5px">
              <button @click="editExam()" type="button" class="btn btn-outline-success btn-sm">تعديل</button>
            </div>
          </div>
          <div v-if="record.id > 0" class="col-4" style="padding: 0px">
            <div class="d-grid gap-2" style="padding:5px">
              <button @click="deleteExam(record.id)" type="button" class="btn btn-outline-danger btn-sm">حذف</button>
            </div>
          </div>
          <!-- ***** Status ***** -->
          <div v-if="record.id > 0" class="col-6" style="text-align: right; padding: 5px">
            <vue-element-loading :active="spinnerStatusShow" spinner="line-scale" color="#E9E9E9" />
            <div :class="record.status ? 'border-success' : 'border-danger'" class="card mb-3" :style="record.status ? 'background-color: #5cb85c' : 'background-color: #d9534f' " style="max-width: 10rem;margin:0px 5px 0px 0px ;padding:0px;" >
              <div class="card-body text-center" style="padding:5px">
                <h5 class="card-title"  style="color:#ffffff">حالة النشر</h5>
                <h4 class="card-title"  style="color:#ffffff">{{record.statusStr}}</h4>
              </div>
              <div :class="record.status ? 'border-success' : 'border-danger'"  class="card-footer text-center" style="background-color: #f5f5f5"  >
                <div class="form-check form-switch" style="text-align: right;margin:0px; padding: 0px 0px 0px 90px">
                  <input @change="updateStatus()" class="form-check-input" type="checkbox" role="switch" :id=" 'brands_' + record.id" v-model="record.status" :value="record.id">
                </div>
              </div>
            </div>
          </div>
          <!-- ***** Status ***** -->
          <div v-if="record.id > 0" class="col-6" style="text-align: right; padding: 5px">
            <vue-element-loading :active="spinnerAnswerStatusShow" spinner="line-scale" color="#E9E9E9" />
            <div :class="record.answer_status ? 'border-success' : 'border-danger'" class="card mb-3" :style="record.answer_status ? 'background-color: #5cb85c' : 'background-color: #d9534f' " style="max-width: 10rem;margin:0px 5px 0px 0px ;padding:0px;" >
              <div class="card-body text-center" style="padding:5px">
                <h5 class="card-title"  style="color:#ffffff">حالة الاجابة</h5>
                <h4 class="card-title"  style="color:#ffffff">{{record.answer_statusStr}}</h4>
              </div>
              <div :class="record.answer_status ? 'border-success' : 'border-danger'"  class="card-footer text-center" style="background-color: #f5f5f5"  >
                <div class="form-check form-switch" style="text-align: right;margin:0px; padding: 0px 0px 0px 90px">
                  <input @change="updateAnswerStatus()" class="form-check-input" type="checkbox" role="switch" :id=" 'brands_' + record.id" v-model="record.answer_status" :value="record.id">
                </div>
              </div>
            </div>
          </div>
          <div v-if="record.id > 0" class="col-6" style="text-align: right; padding: 5px">
            <div class="card border-success mb-3" style="max-width: 10rem;margin:0px 0px 0px 5px ;padding:0px;background-color: #5cb85c" >
              <div class="card-body text-success text-center" style="padding:5px">
                <h5 class="card-title"  style="color:#ffffff">عدد الأسئلة</h5>
                <h4 class="card-title"  style="color:#ffffff">{{record.questions_count}}</h4>
              </div>
              <div class="card-footer  border-success text-center" style="background-color: #f5f5f5;padding:5px"  >
                <table class="table table-success table-striped">
                  <thead>
                    <tr>
                      <th scope="col" style = "padding:1px;font-size:13px">صح وخطأ</th>
                      <th scope="col" style = "padding:1px;font-size:13px">الاختيارى</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style = "padding:1px;font-size:11px">{{record.tf_count}}</td>
                      <td style = "padding:1px;font-size:11px">{{record.choice_count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="record.id > 0" @click = "showDetails(record.id, record.class_id)" class="col-6" data-bs-toggle="modal" data-bs-target="#detailsExamModal" style="text-align: right; padding: 5px">
            <div class="card border-success mb-3" style="max-width: 10rem;margin:0px 0px 0px 5px ;padding:0px;background-color: #5cb85c" >
              <div class="card-body text-success text-center" style="padding:5px">
                <h5 class="card-title"  style="color:#ffffff">عدد الطلاب</h5>
                <h4 class="card-title"  style="color:#ffffff">{{record.all_students}}</h4>
              </div>
              <div class="card-footer  border-success text-center" style="background-color: #f5f5f5;padding:5px"  >
                <table class="table table-success table-striped">
                  <thead>
                    <tr>
                      <th scope="col" style = "padding:1px;font-size:13px">اختبر</th>
                      <th scope="col" style = "padding:1px;font-size:13px">باقى</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style = "padding:1px;font-size:11px">{{record.done}}</td>
                      <td style = "padding:1px;font-size:11px">{{record.not_done}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- New Exam Modal Start -->
<div class="modal fade" id="newExamModal" ref="newExamModal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div v-if="isSaved == true"  class="alert alert-success" role="alert">
          تم حفظ وانشاء الاختبار بنجاح
        </div>
        <div v-if="isSaved == false">
          <div class="row align-items-end" style="text-align: right;padding: 5px  15px  15px  15px">
            <div class="col-12" style="text-align: right; padding: 5px">
              <label for="exampleFormControlInput1" class="form-label">الصف:</label>
              <select @change="getSubjects();resetData()" class="form-select form-select-sm" v-model="recordNew.grade_id">
                <option v-for="item in gradeArr" v-bind:key="item.id" v-bind:value="item.id">
                  {{ item.grade_name }}
                </option>
              </select>
            </div>
            <div class="col-12" style="text-align: right; padding: 5px">
              <label for="exampleFormControlTextarea1" class="form-label">الفصل</label>
              <vue-element-loading :active="spinnerSubjectShow" spinner="line-scale" color="#E9E9E9" />
              <select class="form-select form-select-sm" v-model="recordNew.class_id">
                <option v-for="item in classesArr" v-bind:key="item.id" v-bind:value="item.id">
                  {{ item.class_name }}
                </option>
              </select>
            </div>
            <div class="col-12" style="text-align: right; padding: 5px">
              <label for="exampleFormControlTextarea1" class="form-label">المادة</label>
              <vue-element-loading :active="spinnerSubjectShow" spinner="line-scale" color="#E9E9E9" />
              <select @change="resetData()" class="form-select form-select-sm" v-model="recordNew.subject_id">
                <option v-for="item in SubjectArr" v-bind:key="item.id" v-bind:value="item.id">
                  {{ item.subject_name }}
                </option>
              </select>
            </div>
            <div class="col-12" style="text-align: right; padding: 5px">
              <label for="exampleFormControlTextarea1" class="form-label">الاتجاه</label>
              <select class="form-select form-select-sm" v-model="recordNew.rtl">
                <option v-for="item in rtlType" v-bind:key="item.id" v-bind:value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-12" style="text-align: right; padding: 5px">
              <label for="exampleFormControlInput1" class="form-label">التاريخ</label>
              <div>
                <Datepicker
                v-model="date"
                :enableTimePicker="false"
                weekStart="6"
                :format="format"
                autoApply
                :clearable="false"
                />
              </div>
            </div>
            <div class="col-12" style="text-align: right; padding: 5px">
              <label for="exampleFormControlInput1" class="form-label">اسم الاختبار</label>
              <input type="text" class="form-control" v-model="recordNew.exam_name">
            </div>
            <div class="d-grid col-6" style="padding: 10px 2px 1px 5px">
              <a class=" btn btn-outline-primary btn-sm"  :download="downloadFile.file" :href="downloadFile.url" role="button">تحميل قالب
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-spreadsheet" viewBox="0 0 16 16">
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z"/>
                </svg>
              </a>
            </div>
            <div class="d-grid col-6" style="padding: 10px 2px 1px 5px">
              <button @click="$refs.file.click()" type="button" class="btn btn-outline-primary btn-sm">استيراد
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z"/>
                </svg>
              </button>
            </div>
            <div v-if= "recordNew.questions_count > 0" class="col-12 text-center" style="text-align: right; padding: 5px">
              <table class="table table-success table-striped">
                <thead>
                  <tr>
                    <th scope="col" style = "padding:1px;font-size:13px">العدد</th>
                    <th scope="col" style = "padding:1px;font-size:13px">صح وخطأ</th>
                    <th scope="col" style = "padding:1px;font-size:13px">الاختيارى</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style = "padding:1px;font-size:11px">{{recordNew.questions_count}}</td>
                    <td style = "padding:1px;font-size:11px">{{recordNew.tf_count}}</td>
                    <td style = "padding:1px;font-size:11px">{{recordNew.choice_count}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-grid col-12" style="padding: 10px 2px 1px 5px">
              <button v-if="isSaved == false" @click="createExam()" type="button" class="btn btn-outline-primary btn-sm">
                إنشاء
              </button>
            </div>
      <div class="upload-btn-wrapper">
        <input
        ref="file"
        style="display: none"
        type="file"
        v-on:change="addfile($event)"
        placeholder="Upload file"
        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">خروج</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- New Exam Modal End -->

<!-- Details Modal Start -->
<div class="modal" id="detailsExamModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">النتيجة</h5>
      </div>
      <div class="modal-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style="padding:2px">#</th>
              <th scope="col" style="padding:2px">الاسم</th>
              <th scope="col" style="padding:2px">الدرجة</th>
            </tr>
          </thead>
          <tbody>
            <tr  v-for='(item, index) in resultRec' :key='item.id'>
              <th scope="row" style="padding:2px;font-size:11px">{{index + 1}}</th>
              <td style="padding:2px;font-size:11px" align='right'>{{item.student_name}}</td>
              <td style="padding:2px;font-size:11px">{{item.result}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إغلاق</button>
      </div>
    </div>
  </div>
</div>
<!-- Details Modal End -->

</template>
<style media="screen">
a:link {
text-decoration: none;
}

a:visited {
text-decoration: none;
}

a:hover {
text-decoration: none;
}

a:active {
text-decoration: none;
}
</style>
<script>
/* eslint-disable */
import * as XLSX from 'xlsx'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue'
import VueElementLoading from 'vue-element-loading'
export default {
 components: {
   VueElementLoading,
  Datepicker,
 },
 name: 'ExamsView',
 setup() {
   const date = ref(new Date())
   const format = (date) => {
       const day = date.getDate()
       const month = date.getMonth() + 1
       const year = date.getFullYear()
       return `${year}/${month}/${day}`
   }
   return {
   date,
   format,
 }
},
 data () {
   return {
     resultRec: '',
     rtlType: [{id: 1, name: 'من اليمين'}, {id: 2, name: 'من اليسار'}],
     downloadFile: {file: 'exam.xlsx', url: 'https://sa.amals.pro/api/system/storage/app/exam-temp-files/exam.xlsx'},
     //---------------------------------------------------------
     modalDetailsrecord: [],
     recordNew: [],
     isSaved: false,
     spinnerExam: false,
     spinnerAnswerStatusShow: false,
     record: {
       exam_name: '',
       grade_id: 0,
       id: 0,
       questions_count: 0,
       status: false,
       statusStr: 'غير نشط',
       student_count: 0
     },
     records: '',
     examArr: [],
     gradeArr: [],
     SubjectArr: [],
     classesArr: [],
     unitsArr: [],
     spinnerShow: false,
     spinnerSubjectShow: false,
     spinnerStatusShow: false,
     spinnerLessons: false,
     spinnerStudents: false,
     exam_name: ''
   }
 },
 created () {
   this.newExam()
   this.spinnerExam = true
   this.$store.dispatch('callApi', {
     url: 'm-get-exams'
   })
     .then(res => {
       this.spinnerExam = false
       this.examArr = res.exams
       this.gradeArr = res.grades
     })
     .catch(err => {
       this.spinnerExam = false
       alert(err[0])
     })
   },
   methods: {
 // =========================================================================
  getSubjects () {
    this.spinnerSubjectShow = true
    this.$store.dispatch('callApi', {
      url: 'm-get-exam-subjects',
      data: this.recordNew.grade_id
    })
      .then(res => {
        this.spinnerSubjectShow = false
        this.SubjectArr = res.Subjects
        this.classesArr = res.classes

      })
      .catch(err => {
        this.spinnerSubjectShow = false
        alert(err[0])
      })
  },
  // =========================================================================
  resetData () {
    this.recordNew.student_count = 0
  },
  // =========================================================================
  showDetails (id, classID) {
    this.resultRec = []
    this.spinnerExam = true
    this.recordNew.date = this.date
    this.$store.dispatch('callApi', {
      url: 'get-exam-result',
      data: {
        examID: id,
        class_id: classID
      }
    })
      .then(res => {
         this.spinnerExam = false
         this.resultRec = res
      })
      .catch(err => {
        alert(err[0])
        this.spinnerExam = false
      })
  },
   // =========================================================================
   createExam () {
     this.spinnerExam = true
     this.recordNew.date = this.date
     this.$store.dispatch('callApi', {
       url: 'm-save-exam',
       data: this.recordNew
     })
       .then(res => {
          this.spinnerExam = false
          this.isSaved = true
          this.examArr.push(res)
          this.record = res
       })
       .catch(err => {
         alert(err[0])
         this.spinnerExam = false
       })
    },
   // =========================================================================
   updateStatus () {
     this.spinnerStatusShow = true
     this.$store.dispatch('callApi', {
       url: 'm-update-status',
       data: {id: this.record.id, status: this.record.status}
     })
       .then(res => {
         this.spinnerStatusShow = false
         this.record.statusStr = res.statusStr
         this.record.status = res.status
         let i = this.examArr.map(record => record.id).indexOf(this.record.id)
         this.examArr[i].statusStr = res.statusStr
         this.examArr[i].status = res.status
       })
   },
   // =========================================================================
   updateAnswerStatus () {
     this.spinnerAnswerStatusShow = true
     this.$store.dispatch('callApi', {
       url: 'm-update-answer-status',
       data: {id: this.record.id, answer_status: this.record.answer_status}
     })
       .then(res => {
         this.spinnerAnswerStatusShow = false
         this.record.answer_statusStr = res.answer_statusStr
         this.record.answer_status = res.answer_status
         let i = this.examArr.map(record => record.id).indexOf(this.record.id)
         this.examArr[i].answer_statusStr = res.answer_statusStr
         this.examArr[i].answer_status = res.answer_status
       })
   },
   // =========================================================================
   newExam () {
     this.isSaved = false
     this.exam_name = ''
     this.classesArr = []
     this.recordNew = {
         id: 0,
         grade_id: 0,
         class_id: 0,
         subject_id: 0,
         rtl: 1,
         exam_name: '',
         questions: [],
         questions_count: 0,
         status: false,
         statusStr: 'غير نشط',
         student_count: 0,
         questions_count: 0,
         tf_count: 0,
         choice_count: 0
       }
     },
   // =========================================================================
   editExam () {
     let exam_name = prompt("اسم الاختبار:", this.record.exam_name).trim()
     if (exam_name != null && exam_name.trim() != "") {
       this.spinnerExam = true
       this.$store.dispatch('callApi', {
         url: 'm-update-exam-name',
         data: {id: this.record.id, exam_name: exam_name}
       })
         .then(res => {
           this.spinnerExam = false
           this.record.exam_name = exam_name
           let i = this.examArr.map(record => record.id).indexOf(this.record.id)
           this.examArr[i].exam_name =exam_name
         })
     }
   },
   // =========================================================================
   deleteExam (id) {
     if (confirm('هل تريد بالتاكيد حذف الامتحان') == true) {
       this.newExam()
       this.spinnerExam = true
       this.$store.dispatch('callApi', {
         url: 'delete-exam',
         data: id
       })
         .then(res => {
           this.spinnerExam = false
           this.examArr = res
           this.record.id = 0
         })
      }
   },
   // =========================================================================
   addfile (event) {
     this.recordNew.questions = ''
     this.recordNew.tf_count = 0
     this.recordNew.choice_count = 0
     this.recordNew.questions_count = 0
     this.file = event.target.files[0]
     let fileReader = new FileReader()
     fileReader.readAsArrayBuffer(this.file)
     fileReader.onload = () => {
       this.arrayBuffer = fileReader.result
       var data = new Uint8Array(this.arrayBuffer)
       var arr = []
       for (var i = 0; i !== data.length; ++i) {
         arr[i] = String.fromCharCode(data[i])
       }
       var bstr = arr.join('')
       var workbook = XLSX.read(bstr, { type: 'binary' })
       var firstSheetName = workbook.SheetNames[0]
       var worksheet = workbook.Sheets[firstSheetName]
       var sheetRecords = XLSX.utils.sheet_to_json(worksheet, { raw: true })
       if (sheetRecords.length > 0) {
         this.recordNew.questions = sheetRecords
         this.recordNew.questions_count = sheetRecords.length
         for (let i = 0; i < this.recordNew.questions.length; i++ ) {
           if (this.recordNew.questions[i]['c3'] === undefined) {
             this.recordNew.questions[i]['c3'] = null
             this.recordNew.questions[i]['c4'] = null
            this.recordNew.tf_count = this.recordNew.tf_count + 1
           } else {
             this.recordNew.choice_count = this.recordNew.choice_count + 1
           }
           if (this.recordNew.questions[i]['c1'] === undefined) {
             this.recordNew.questions[i]['c1'] = null
           }
           if (this.recordNew.questions[i]['c2'] === undefined) {
             this.recordNew.questions[i]['c2'] = null
           }
           if (this.recordNew.questions[i]['c4'] === undefined) {
             this.recordNew.questions[i]['c4'] = null
           }
          }
       }
     }
   },
 }
}
</script>
