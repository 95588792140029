<template>
  <div class="container" style="padding-top: 10px">
    <vue-element-loading :active="spinnerShow" is-full-screen  spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
    <div class="col" style="padding: 5px">
      <div class="container text-center">
        <div class="row justify-content-evenly">
          <div class="row text-center" style="padding: 0px 0px 0px 0px">
            <div class="col-11" >
              <p class="h4" style="margin:0px; padding:5px;color:#8c1af6"><b>الواجبات</b></p>
            </div>
            <div class="col-1" style="padding:2px">
              <svg
              @click="this.$router.push('/home')"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16">
              <path
              fill-rule="evenodd"
              fill="#8c1af6"
              font-weight="bold"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fixedscr" >
    <div class="card" style="width: 100%;">
      <div class="card-body">
        <div class="row align-items-end" style="text-align: right;">
          <div class="col-6" style="padding: 0px 5px">
            <label for="exampleFormControlInput1" class="form-label">الفصل</label>
            <select @change="getSubjects()" class="form-select form-select-sm" v-model="classID">
              <option v-for="item in classArr" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-6" style="padding: 0px 5px">
            <label for="exampleFormControlInput1" class="form-label">المادة</label>
            <div>
              <vue-element-loading :active="spinnerSubjectShow" spinner="line-scale" color="#E9E9E9" />
              <select @change="datepickerChange()" class="form-select form-select-sm" v-model="Subject">
                <option v-for="item in SubjectArr" v-bind:key="item.id" v-bind:value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row align-items-end" style="padding: 0px 12px 0px 0px">
            <div class="col-6" style="padding: 5px 5px">
              <label for="exampleFormControlInput1" class="form-label">اليوم</label>
              <div>
                <Datepicker
                @closed="datepickerChange()"
                v-model="date"
                :enableTimePicker="false"
                weekStart="6"
                :format="format"
                autoApply
                :clearable="false"
                :disabledWeekDays="[5]"
                />
              </div>
            </div>
            <div class="d-grid gap-2 col-6 mx-auto" style="padding: 5px 5px; margin: 0px 5px 2px 0px">
              <button @click="GetData()" type="button" class="btn btn-outline-primary btn-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                  <path fill="blue" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>  بحث
              </button>
            </div>
          </div>
          <div v-show="!isNew" class="form-check form-switch" style="padding:5px">
            <input class="form-check-input" style="padding:0px;margin:0px" type="checkbox" role="switch" v-model="record.status" >
            <label for="exampleFormControlTextarea1" class="form-label">الواجب</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" v-model="record.text_str"></textarea>
          </div>
          <div  v-show="record.id > 0" style="text-align: center; padding:5px">
            <div  v-for='(item, index) in PicArr' :key='index' v-show="item.id > 0" class="card" style="width: 100%;padding:10px;margin-bottom:5px">
              <span class="notify-badge" @click="deleteimg(item)">×</span>
              <img :src="'https://sa.amals.pro/api/system/storage/app/homework-img/' + item.pic_id" class="card-img-bottom" alt="...">
            </div>
            <div class="card" style="width: 100%;padding:10px;margin-bottom:5px">
              <div class="dropZoneContainer">
                <input type="file" id="drop_zone" class="FileUpload" accept=".jpg,.png,.gif" @change="onFileChange" />
                <div class="dropZoneOverlay">إضافة صورة جديدة</div>
              </div>
            </div>
          </div>
          <div  v-show="!isNew" class="d-grid gap-2" style="padding: 5px">
            <button  v-show="!isNew" @click="saveData()" type="button" class="btn btn-success btn-md">حفظ</button>
          </div>
          <div v-show="record.id > 0" class="row" style="padding-right: 25px">
            <div class="d-grid gap-2 col-4" style="padding: 2px">
              <button @click="newData()" type="button" class="btn btn-outline-primary btn-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                  <path fill="blue" d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                </svg> جديد
              </button>
            </div>
            <div   class="d-grid gap-2 col-4" style="padding: 2px">
              <button @click="newShearData()" type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#shareModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="blue" d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/>
                </svg> مشاركة
              </button>
            </div>
            <div class="d-grid gap-2 col-4" style="padding: 2px">
              <button @click="deleteData()" type="button" class="btn btn-outline-danger btn-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" fill="red" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg> حذف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="shareModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="col" style="text-align: right">
          <label for="exampleFormControlInput1" class="form-label">الفصل</label>
          <select class="form-select form-select-sm" v-model="shareClassID">
            <option v-for="item in classArr" v-bind:key="item.id" v-bind:value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col" style="text-align: right">
          <label for="exampleFormControlInput1" class="form-label">اليوم</label>
          <div>
            <Datepicker
            v-model="shareDate"
            :enableTimePicker="false"
            weekStart="6"
            :format="format"
            autoApply
            :clearable="false"
            :disabledWeekDays="[5]"
            />
          </div>
        </div>
        <div class="form-check form-switch" style="text-align: right;margin: 5px 0px; padding: 0px 0px 0px 50px">
          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="shareStatus" >
          <label class="form-check-label" for="flexSwitchCheckChecked">حالة الواجب</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">تراجع</button>
        <button type="button" @click="saveShare()" class="btn btn-primary">حفظ</button>
      </div>
    </div>
  </div>
</div>
</template>
<style media="screen">
.dropZoneOverlay, .FileUpload {
    width: 100%px;
    height: 100%px;
}

.dropZoneOverlay {
    color: #7066fb;
    top: 0px;
    text-align: center;
}

.FileUpload {
    opacity: 0;
    position: relative;
    z-index: 1;
}
.notify-badge{
    position: absolute;
    right:8px;
    top:8px;
    background:red;
    text-align: center;
    border-radius: 40px 40px 40px 40px;
    color:white;
    padding:0px 10px;
    font-size:25px;
}
</style>
<script>
/* eslint-disable */
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue'
import VueElementLoading from 'vue-element-loading'
export default {
  components: {
    VueElementLoading,
    Datepicker,
  },
  name: 'HomeWork',
  setup() {
    const date = ref(new Date())
    const format = (date) => {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${year}/${month}/${day}`
    }
    return {
    date,
    format,
}
},
  data () {
    return {
      isNew: true,
      spinnerSubjectShow: false,
      spinnerExamShow: false,
      record: [],
      classArr: '',
      classID: null,
      SubjectArr: [],
      Subject: null,
      shareClassID: null,
      spinnerShow: false,
      shareDate: '',
      PicArr: '',
      shareStatus: false,
      shareModalShow: true,
      ifShareSuccess: false
    }
  },
  created () {
    var today = new Date()
    let myDay = String(today.getDate()).padStart(2, '0')
    let myMonth = String(today.getMonth() + 1).padStart(2, '0')
    let myYear = today.getFullYear()
    this.shareDate = myYear + '-' + myMonth + '-' + myDay
    this.spinnerShow = true
    this.$store.dispatch('callApi', {
      url: 'm-get-classes'
    })
      .then(res => {
        this.spinnerShow = false
        this.classArr = res
      })
      .catch(err => {
        this.spinnerShow = false
        this.Errors = err
      })
    },
    methods: {
      getImgUrl (pet) {
        var images = require.context('@/assets/', false, /\.png$/)
        return images('./' + pet + '.png')
      },
      // =========================================================================
      getSubjects () {
        this.record = []
        this.isNew = true
        this.Subject = null
        this.spinnerSubjectShow = true
        this.$store.dispatch('callApi', {
          url: 'm-get-subject',
          data: this.classID
        })
          .then(res => {
            this.spinnerSubjectShow = false
            this.SubjectArr = res
          })
          .catch(err => {
            this.spinnerSubjectShow = false
            alert(err[0])
          })
        },
  // =========================================================================
    datepickerChange () {
      this.record = []
      this.isNew = true
    },
  // =========================================================================
    GetData () {
      this.isNew = true
      this.spinnerShow = true
      this.$store.dispatch('callApi', {
        url: 'm-get-homework',
        data: {'class': this.classID, 'date': this.date, 'subject': this.Subject}
      })
        .then(res => {
          this.record = res.data[0]
          this.PicArr = res.pics
          this.spinnerShow = false
          this.isNew = false
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
          this.isNew = true
        })
    },
    // =========================================================================
    newData () {
      this.record = []
      this.isNew = true
      this.classID = null
      this.Subject = null

    },
    // =========================================================================
    newShearData () {
      this.shareStatus = true
      this.shareClassID = null

    },
    // =========================================================================
    saveData () {
      this.spinnerShow = true
      this.$store.dispatch('callApi', {
        url: 'm-save-homework',
        data: this.record
      })
      .then(res => {
          this.spinnerShow = false
          if (res.status === 1) {
            res.status = true
          } else {
            res.status = false
          }
          this.record = res
          alert('تم الحفظ بنجاح')
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
        })
    },
    // =========================================================================
    saveShare () {
      this.spinnerShow = true
      this.$store.dispatch('callApi', {
        url: 'm-save-share-homework',
        data: {'id': this.record.homework_id, 'ClassID': this.shareClassID, 'Date': this.shareDate, 'status': this.shareStatus}
      })
      .then(res => {
          alert('تم الحفظ بنجاح')
          this.spinnerShow = false
          this.ifShareSuccess = true
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
          this.ifShareSuccess = false
        })
    },
    // =========================================================================
    onFileChange (event) {
      this.spinnerShow = true
      this.$store.dispatch('uploadFiles', {
        file: event.target.files[0],
        type: 1,
        id: this.record.homework_id
      })
      .then(res => {
          this.spinnerShow = false
          for (let i = 0; i < this.PicArr.length; i++) {
            if (this.PicArr[i].id === 0) {
              this.PicArr[i].id = res.id
              this.PicArr[i].pic_id = res.filename
              break
            }
          }
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
        })
    },
    // =========================================================================
    deleteimg (pic) {
      if (confirm('هل تريد بالتأكيد حذف هذه الصورة؟')) {
        this.spinnerShow = true
        this.$store.dispatch('callApi', {
          url: 'm-delete-homework-pic',
          data: {'id': pic.id, 'pic_name' : pic.pic_id}
        })
      .then(res => {
          this.spinnerShow = false
          for (let i = 0; i < this.PicArr.length; i++) {
            if (this.PicArr[i].id === pic.id) {
              this.PicArr[i].id = 0
              this.PicArr[i].pic_id = null
              break
            }
          }
        })
        .catch(err => {
          alert(err[0])
          this.spinnerShow = false
        })
    }
  },
    // =========================================================================
    deleteData () {
      if (confirm('هل تريد بالتأكيد حذف الواجب الحالى؟')) {
        this.spinnerShow = true
        this.$store.dispatch('callApi', {
          url: 'm-delete-homework',
          data: this.record.id
        })
        .then(res => {
            this.spinnerShow = false
            this.newData()
            alert('تم الحذف بنجاح')
          })
          .catch(err => {
            alert(err[0])
            this.spinnerShow = false
          })
      }
    }
  }
}
</script>
