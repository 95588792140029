<template>
<div class="container" style="padding-top: 10px">
  <vue-element-loading :active="spinnerShow" is-full-screen  spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
  <div class="col" style="padding: 5px">
    <div class="container text-center">
      <div class="row justify-content-evenly">
        <div class="row text-center" style="padding: 0px 0px 0px 0px">
          <div class="col-11" >
            <p class="h4" style="margin:0px; padding:5px;color:#8c1af6"><b>البيانات الشخصية</b></p>
          </div>
          <div class="col-1" style="padding:2px">
            <svg
              @click="this.$router.push('/home')"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16">
              <path
                fill-rule="evenodd"
                fill="#8c1af6"
                font-weight="bold"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="list-group">
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">كود الوزارة:</h6>
      </div>
      <p class="mb-1">{{record.ministry_code}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">الاسم بالكامل:</h6>
      </div>
      <p class="mb-1">{{record.emp_name}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">الاسم المختصر:</h6>
        <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#editModal" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
          <path fill="green" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
        </svg>
      </div>
      <p class="mb-1">{{record.short_name}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">المحمول:</h6>
        <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#editModal" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
          <path fill="green" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
        </svg>
      </div>
      <p class="mb-1">{{record.mobile}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">الرقم القومى:</h6>
      </div>
      <p class="mb-1">{{record.nid}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">تاريخ التعيين:</h6>
      </div>
      <p class="mb-1">{{record.hiring_date}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">الدرجه المالية:</h6>
      </div>
      <p class="mb-1">{{record.financial_degree}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">تاريخ الدرجة:</h6>
      </div>
      <p class="mb-1">{{record.degree_date}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">المجموعة النوعية:</h6>
      </div>
      <p class="mb-1">{{record.qualitative_group}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">الوظيفة الحالية:</h6>
      </div>
      <p class="mb-1">{{record.job}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">نوع المؤهل:</h6>
      </div>
      <p class="mb-1">{{record.qualification_type}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">المؤهل:</h6>
      </div>
      <p class="mb-1">{{record.qualification}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">الوظيفة على الكادر:</h6>
      </div>
      <p class="mb-1">{{record.staff_job}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">تاريخ الوظيفة على الكادر:</h6>
      </div>
      <p class="mb-1">{{record.staff_job_date}}</p>
    </li>
    <li class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">رقم بطاقة التأمين الصحى:</h6>
        <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal" data-bs-target="#editModal" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
          <path fill="green" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
        </svg>
      </div>
      <p class="mb-1">{{record.insurance_id}}</p>
    </li>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-sm">
  <div class="modal-content">
    <div class="modal-body">
      <div v-if='alertStr' :class="ifSuccess ? 'alert-success' : 'alert-danger'" class="alert" role="alert" style="text-align: right">
        <li v-for='err in alertStr'  v-bind:key='err.key' v-bind:value='err.key'>{{ err }}</li>
      </div>
      <div class="mb-3" style="text-align: right">
        <label for="exampleFormControlInput1" class="form-label">الاسم المختصر</label>
        <input v-model="record.short_name" type="text" class="form-control" >
      </div>
      <div class="mb-3" style="text-align: right">
        <label for="exampleFormControlInput1" class="form-label">المحمول</label>
        <input v-model="record.mobile" type="text"  class="form-control">
      </div>
      <div class="mb-3" style="text-align: right">
        <label for="exampleFormControlInput1" class="form-label">رقم بطاقة التأمين الصحى</label>
        <input v-model="record.insurance_id" type="text"  class="form-control" >
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">تراجع</button>
      <button type="button" @click="saveData()" class="btn btn-primary">حفظ</button>
    </div>
  </div>
</div>
</div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
export default {
  components: {
    VueElementLoading
  },
  name: 'PersonalData',
  data () {
    return {
      record: [],
      spinnerShow: false,
      ifSuccess: false,
      alertStr: ''
    }
  },
  created () {
    this.spinnerShow = true
    this.$store.dispatch('callApi', {
      url: 'm-get-employee'
    })
      .then(res => {
        this.spinnerShow = false
        this.record = res
      })
      .catch(err => {
        this.spinnerShow = false
        this.Errors = err
      })
    },
  methods: {
    getImgUrl (pet) {
      var images = require.context('@/assets/', false, /\.png$/)
      return images('./' + pet + '.png')
    },
    // =========================================================================
    saveData () {
      this.spinnerShow = true
      this.alertStr = ''
      this.$store.dispatch('callApi', {
        url: 'm-save-personal-data',
        data: this.record
      })
        .then(res => {
          this.record = res
          this.spinnerShow = false
          this.ifSuccess = true
          this.alertStr = {0: 'تم الحفظ بنجاح'}
        })
        .catch(err => {
          this.alertStr = err
          this.spinnerShow = false
          this.ifSuccess = false
        })
    }
  }
}
</script>
