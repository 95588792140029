<template>
  <div class="row align-items-center" style="padding: 10px 10px 0px 10px; margin: 0px;text-align: right">
    <div class="col-3" style="padding: 0px">
      <vue-element-loading :active="spinnerShow" spinner="line-scale" color="#E9E9E9" />
      <input type="file" ref="file" style="display: none"  accept=".jpg,.png,.gif" @change="onFileChange" />
      <img  @click="$refs.file.click()" :src="imgURL" class="avatar">
    </div>
    <div class="col-9" style="padding: 0px 10px 0px 0px">
      <p class="h6">مرحبا <br> {{teacherName}}</p>
        <p style="font-size:10px" class="h6">app version: {{version}}</p>
    </div>
  </div>
  <hr style="width:100%;text-align:left;margin-left:0; margin:0px ;color:#8c1af6">
  <div class="" style="padding-bottom: 15px">
    <router-view/>
  </div>
  <div>
    <p style="font-size:10px" class="h6">جميع الحقوق محفوظة©</p>
    <p style="font-size:10px" class="h6">    <a href="https://facebook.openinapp.co/alaanasem" >
          علاء نسيم الهليهي
        </a></p>
    <p style="font-size:10px" class="h6">2024</p>
  </div>

</template>

<style>
.avatar {
   vertical-align: middle;
   width: 80px;
   height: 80px;
   border-radius: 50%;
   padding: 2px;
   border: 3px solid #ddd;
   margin: 5px;
}
</style>
<script>
/* eslint-disable */
import VueElementLoading from 'vue-element-loading'
import packageInfo from '../../package'
export default {
  components: {
    VueElementLoading
  },
  name: 'HomePage',
  computed: {
    getCurrentPage() {
      return this.$store.state.currentPage
    }
  },
  data () {
    return {
      version: packageInfo.version,
      imgURL: 'https://sa.amals.pro/api/system/storage/app/av-img/' + sessionStorage.getItem('avt_img'),
      teacherName: '',
      spinnerShow: false
    }
  },
  created () {
    this.teacherName = sessionStorage.getItem('name')
  },
  methods: {
    getImgUrl (pet) {
      var images = require.context('@/assets/', false, /\.png$/)
      return images('./' + pet + '.png')
    },
    // =========================================================================
    onFileChange (event) {
      this.spinnerShow = true
      this.alertShareStr = ''
      this.$store.dispatch('uploadFiles', {
        file: event.target.files[0],
        type: 0,
        id: 0
      })
        .then(res => {
          this.spinnerShow = false
          this.imgURL= 'https://sa.amals.pro/api/system/storage/app/av-img/' + res
        })
        .catch(err => {
          this.alertStr = err
          this.spinnerShow = false
          this.ifSuccess = false
        })
    }
  }
}
</script>
