<template>
  <div class="container" style="padding-top: 10px">
    <vue-element-loading :active="spinnerShow" is-full-screen  spinner="bar-fade-scale" color="#D0D0D0" text="جارى تحميل البيانات" />
    <div class="col" style="padding: 5px">
      <div class="container text-center">
        <div class="row justify-content-evenly">
          <div class="row text-center" style="padding: 0px 0px 0px 0px">
            <div class="col-11" >
              <p class="h4" style="margin:0px; padding:5px;color:#8c1af6"><b>جدول الحصص</b></p>
            </div>
            <div class="col-1" style="padding:2px">
              <svg
                @click="this.$router.push('/home')"
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="currentColor"
                class="bi bi-chevron-left"
                viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  fill="#8c1af6"
                  font-weight="bold"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
              </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            جدول يوم السبت
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body" style="text-align: right;padding:5px">
            <ol class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start" style="padding: 0px 5px;background-color:#FCFCFC" >
                <table class="table" style="background-color:#FCFCFC">
                  <thead>
                    <tr>
                      <th scope="col" style="text-align: center;padding: 2px">الحصة</th>
                      <th scope="col" style="text-align: center;padding: 2px">المادة</th>
                      <th scope="col" style="text-align: center;padding: 2px">الفصل</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="item in day1" :key="item.id">
                      <th scope="row" style="text-align: center;padding: 2px">{{item.period_id}}</th>
                      <td style="text-align: center;padding: 2px" >{{item.subject_name}}</td>
                      <td style="text-align: center;padding: 2px">{{item.class_name}}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            جدول يوم الأحــد
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body" style="text-align: right;padding:5px">
            <ol class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start" style="padding: 0px 5px;background-color:#FCFCFC" >
                <table class="table" style="background-color:#FCFCFC">
                  <thead>
                    <tr>
                      <th scope="col" style="text-align: center;padding: 2px">الحصة</th>
                      <th scope="col" style="text-align: center;padding: 2px">المادة</th>
                      <th scope="col" style="text-align: center;padding: 2px">الفصل</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="item in day2" :key="item.id">
                      <th scope="row" style="text-align: center;padding: 2px">{{item.period_id}}</th>
                      <td style="text-align: center;padding: 2px" >{{item.subject_name}}</td>
                      <td style="text-align: center;padding: 2px">{{item.class_name}}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            جدول يوم الاثنين
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div class="accordion-body" style="text-align: right;padding:5px">
            <ol class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start" style="padding: 0px 5px;background-color:#FCFCFC" >
                <table class="table" style="background-color:#FCFCFC">
                  <thead>
                    <tr>
                      <th scope="col" style="text-align: center;padding: 2px">الحصة</th>
                      <th scope="col" style="text-align: center;padding: 2px">المادة</th>
                      <th scope="col" style="text-align: center;padding: 2px">الفصل</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="item in day3" :key="item.id">
                      <th scope="row" style="text-align: center;padding: 2px">{{item.period_id}}</th>
                      <td style="text-align: center;padding: 2px" >{{item.subject_name}}</td>
                      <td style="text-align: center;padding: 2px">{{item.class_name}}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="heading4">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
            جدول يوم الثلاثاء
          </button>
        </h2>
        <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
          <div class="accordion-body" style="text-align: right;padding:5px">
            <ol class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start" style="padding: 0px 5px;background-color:#FCFCFC" >
                <table class="table" style="background-color:#FCFCFC">
                  <thead>
                    <tr>
                      <th scope="col" style="text-align: center;padding: 2px">الحصة</th>
                      <th scope="col" style="text-align: center;padding: 2px">المادة</th>
                      <th scope="col" style="text-align: center;padding: 2px">الفصل</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="item in day4" :key="item.id">
                      <th scope="row" style="text-align: center;padding: 2px">{{item.period_id}}</th>
                      <td style="text-align: center;padding: 2px" >{{item.subject_name}}</td>
                      <td style="text-align: center;padding: 2px">{{item.class_name}}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="heading5">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
            جدول يوم الأربعاء
          </button>
        </h2>
        <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
          <div class="accordion-body" style="text-align: right;padding:5px">
            <ol class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start" style="padding: 0px 5px;background-color:#FCFCFC" >
                <table class="table" style="background-color:#FCFCFC">
                  <thead>
                    <tr>
                      <th scope="col" style="text-align: center;padding: 2px">الحصة</th>
                      <th scope="col" style="text-align: center;padding: 2px">المادة</th>
                      <th scope="col" style="text-align: center;padding: 2px">الفصل</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="item in day5" :key="item.id">
                      <th scope="row" style="text-align: center;padding: 2px">{{item.period_id}}</th>
                      <td style="text-align: center;padding: 2px" >{{item.subject_name}}</td>
                      <td style="text-align: center;padding: 2px">{{item.class_name}}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="heading6">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
            جدول يوم الخميس
          </button>
        </h2>
        <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
          <div class="accordion-body" style="text-align: right;padding:5px">
            <ol class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start" style="padding: 0px 5px;background-color:#FCFCFC" >
                <table class="table" style="background-color:#FCFCFC">
                  <thead>
                    <tr>
                      <th scope="col" style="text-align: center;padding: 2px">الحصة</th>
                      <th scope="col" style="text-align: center;padding: 2px">المادة</th>
                      <th scope="col" style="text-align: center;padding: 2px">الفصل</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="item in day6" :key="item.id">
                      <th scope="row" style="text-align: center;padding: 2px">{{item.period_id}}</th>
                      <td style="text-align: center;padding: 2px" >{{item.subject_name}}</td>
                      <td style="text-align: center;padding: 2px">{{item.class_name}}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style media="screen">
  .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
  }
  .accordion-button::after {
  background-image: initial;
  width: 0;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: initial;
    width: 0;
  }
</style>
<script>
import VueElementLoading from 'vue-element-loading'
export default {
  components: {
    VueElementLoading
  },
  name: 'TimeTable',
  data () {
    return {
      records: '',
      spinnerShow: false,
    }
  },
  computed: {
    day1() {
      if (!this.records.length) {
        return []
      } else {
        return this.records.filter(record => record.week_days_id === 1)
      }
    },
    day2() {
      if (!this.records.length) {
        return []
      } else {
        return this.records.filter(record => record.week_days_id === 2)
      }
    },
    day3() {
      if (!this.records.length) {
        return []
      } else {
        return this.records.filter(record => record.week_days_id === 3)
      }
    },
    day4() {
      if (!this.records.length) {
        return []
      } else {
        return this.records.filter(record => record.week_days_id === 4)
      }
    },
    day5() {
      if (!this.records.length) {
        return []
      } else {
        return this.records.filter(record => record.week_days_id === 5)
      }
    },
    day6() {
      if (!this.records.length) {
        return []
      } else {
        return this.records.filter(record => record.week_days_id === 6)
      }
    }
  },
  created () {
    this.spinnerShow = true
    this.$store.dispatch('callApi', {
      url: 'm-get-timetable-data'
    })
      .then(res => {
        this.spinnerShow = false
        this.records = res
      })
      .catch(err => {
        this.spinnerShow = false
        this.Errors = err
      })
    }
}
</script>
