import 'bootstrap/dist/css/bootstrap.css'

// import { BIconBatteryFull, BIconArrow90degDown, BIconBookmark } from 'bootstrap-icons-vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


createApp(App)
.use(store)
.use(router)
.mount('#app')

// App.component('BIconBatteryFull', BIconBatteryFull)
// App.component('BIconArrow90degDown', BIconArrow90degDown)
// App.component('BIconBookmark', BIconBookmark)

import 'bootstrap/dist/js/bootstrap.js'
